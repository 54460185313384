
function SectionAbout() {
    return(
        <>
        <section id="about-us" class="about-us">
            <div class="container" data-aos="fade-up">

                <div class="section-title">
                    <h2>Preparation For</h2>
                </div>

                <div class="row content">
                    <div class="col-lg-6" data-aos="fade-right">
                        <ul>
                        <li><i class="ri-check-double-line"></i> Staff Selection Commission (Junior Hindi Translator/ Senior Hindi Translator/ Junior Translation Officer/Senior Translation Officer)</li>
                        <li><i class="ri-check-double-line"></i> Institute of Banking Personnel Selection (IBPS), Specialist Officer(SO)- Rajbhasha Adhikari</li>
                        <li><i class="ri-check-double-line"></i> Railway Recruitment Board(RRB) - Hindi Translator/ JTO</li>
                        </ul>  
                    </div>
                    <div class="col-lg-6 pt-4 pt-lg-0" data-aos="fade-left">
                        <ul>
                        <li><i class="ri-check-double-line"></i> Food Corporation of India - Assistant Grade - II (Hindi)</li>
                        <li><i class="ri-check-double-line"></i> Rajbhasha Adhikari/ Official Language Officer/ Senior Translation Officer/ Junior Translation Officer/Senior Hindi Translator (SHT)/ Junior Hindi Translator (JHT), Hindi Assistant posts in Loksabha, Rajsabha, RBI, NABARD, all PSUs, Central Govt Departments, National Educational Institutes, Autonomous Bodies</li>
                        </ul>
                    </div>
                </div>

            </div>
        </section>
        </>
    );
}
export default SectionAbout;