import {Link} from 'react-router-dom';

import Header from '../include/Header';
import SuccessAllCandidate from '../include/SuccessAllCandidate';

import Footer from '../include/Footer';

const mgtop={
    marginTop: '112px'
};

function Successstory() {
    return(
        <>
        <Header/>
        {/* <Heroslider/> */}
        <main id="main">

        <section id="breadcrumbs" className="breadcrumbs" style={mgtop}>
            <div className="container">

                <div className="d-flex justify-content-between align-items-center">
                <h2>Success Story</h2>
                <ol>
                    <li><Link to="/">Home</Link></li>
                    <li>Success Story</li>
                </ol>
                </div>

            </div>
        </section>
        <SuccessAllCandidate/>

        </main>
        <Footer/>
        </>
    );
}
export default Successstory;