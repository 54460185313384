
function EligibilityCriteria() {

    const imgwidth={
        width: '100%'
    };

    return(
        <>
        <section id="about-us" class="about-us">
            <div class="container" data-aos="fade-up">

                <div class="section-title">
                    <h2>Eligibility Criteria</h2>
                </div>

                <div class="row content">
                    <div class="col-lg-6" data-aos="fade-right">
                        <img src="./assets/img/others/academy-01.jpg" style={imgwidth}/>
                    </div>
                    <div class="col-lg-6 pt-4 pt-lg-0" data-aos="fade-left">
                        <ul>
                        <li><i class="ri-check-double-line"></i> Master's degree in Hindi with English as an elective subject or as the medium of examination at the degree level. OR</li>
                        <li><i class="ri-check-double-line"></i> Master's degree in English with Hindi as an elective subject or as the medium of examination at the degree level.</li>
                        <li><i class="ri-check-double-line"></i> Course Duration - 1 Year</li>
                        <li><i class="ri-check-double-line"></i> Courses Commence in the month of January and July Every Year</li>
                        </ul>
                    </div>
                </div>


            </div>
        </section>

        <section id="about-us" class="about-us">
            <div class="container" data-aos="fade-up">

                <div class="section-title">
                    <h2>What You'll Learn</h2>
                </div>


                <div class="row content">
                    <div class="col-lg-6 pt-4 pt-lg-0" data-aos="fade-left">
                        <ul>
                        <li><i class="ri-check-double-line"></i> सामान्य हिंदी</li>
                        <li><i class="ri-check-double-line"></i> General English</li>
                        <li><i class="ri-check-double-line"></i> Translation from Hindi to English and Vice Versa</li>
                        <li><i class="ri-check-double-line"></i> Essay Writing in Hindi &amp; English</li>
                        <li><i class="ri-check-double-line"></i> राजभाषा नियम एवं अधिनियम</li>
                        <li><i class="ri-check-double-line"></i> Hindi Typing &amp; Software</li>
                        </ul>
                    </div>
                    <div class="col-lg-6" data-aos="fade-right">
                        <img src="./assets/img/others/academy-02.jpg" style={imgwidth}/>
                    </div>
                </div>

            </div>
        </section>
        </>
    );
}
export default EligibilityCriteria;