import {Link} from 'react-router-dom';

import Header from '../include/Header';
import SectionMap from '../include/SectionMap';
import SectionContactForm from '../include/SectionContactForm';

import Footer from '../include/Footer';

const mgtop={
    marginTop: '112px'
};



function Contact() {
    return(
        <>
        <Header/>
        {/* <Heroslider/> */}
        <main id="main">

        <section id="breadcrumbs" className="breadcrumbs" style={mgtop}>
            <div className="container">

                <div className="d-flex justify-content-between align-items-center">
                <h2>Contact</h2>
                <ol>
                    <li><Link to="/">Home</Link></li>
                    <li>Contact</li>
                </ol>
                </div>

            </div>
        </section>
        <SectionContactForm/>
        <SectionMap/>
        
        </main>
        <Footer/>
        </>
    );
}
export default Contact;