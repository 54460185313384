import react, { useEffect, useState } from "react";
import axios from 'axios';
import validator from 'validator';
import {Link} from 'react-router-dom';
import Header from '../include/Header';

import Footer from '../include/Footer';

function Login() {

    if (localStorage.getItem("tokenID")!=null) {
        window.location.href="/stud/dashboard";
      }
    
      // INPUT FIELD
      let [loginid,setLoginid]=useState("");
      let [password,setPassword]=useState("");
      // INPUT FIELD
    
      let [isbtn,setIsbtn]=useState(false);
    
      // VALIDATION
      let errloginid="Valid Email ID Required";
      let errpassword="Password Required";
    
      let [iserrloginid,setIsrrloginid]=useState(false);
      let [iserrpassword,setIsrrpassword]=useState(false);
      // VALIDATION
    
      // MSG
      let [alert,setAlert]=useState(false);
      let [msg,setMsg]=useState("");
      let [msgalert,setMsgalert]=useState("");
      // MSG
    
      async function studLogin() {
        var flag=1;
        console.log(loginid);
        console.log(password);
        if (!validator.isEmail(loginid)) {
          flag=0;
          setIsrrloginid(true);
        }
        if (password.length==0) {
          flag=0;
          setIsrrpassword(true);
        }
        if (flag==1) {
          
          var fd=new FormData();
          fd.append("loginid",loginid);
          fd.append("password",password);
          // var resp=await axios.post({baseurl}+"/api/studlogin",fd);
          var resp=await axios.post("https://safalata.in/safalatalara/api/studlogin",fd);
          
          var data=resp.data;
    
          if (data.msgcode==1) {
            setIsbtn(true);
            localStorage.setItem("tokenID",data.stoken);
            console.log(data.stoken);
            setTimeout(()=>{
              window.location.href="/stud/dashboard";
            },1000)
          }
          setAlert(true);
          setMsg(data.msg);
          setMsgalert(data.alert);
          
        }
        
      }
    

    return(
        <>
        <Header/>
        {/* <Heroslider/> */}
        <main id="main">

        <div className="loginBoxWrap">
        <div className="loginBox">
          <div className="logoRow"><img src="images/logo2.png" alt="" /></div>
        	<div className="bg_white">
          <h2>Student Login</h2>
          <div className="loginBoxContener p-4">
            <p className="text-center">Shraddha Group of Academy</p>
              {alert?<div className={msgalert} style={{textAlign:'center'}}>{msg}</div>:''}
              <div className="form-group row">
                <label for="inputEmail3" className="col-sm-12 col-form-label">Login ID</label>
                <div className="col-sm-12">
                  <input type="email" className="form-control" placeholder="Registered Email/Mobile Number" onChange={(ev)=>{
                    setLoginid(ev.target.value);
                    if (validator.isEmail(loginid)) {
                      setIsrrloginid(false);
                    }
                    setAlert(false);
                  }}/>
                  {iserrloginid?<div className="text text-danger">{errloginid}</div>:''}
                </div>
              </div>
              <div className="form-group row">
                <label for="inputPassword3" className="col-sm-12 col-form-label">Password</label>
                <div className="col-sm-12">
                  <input type="password" className="form-control" placeholder="Enter Password" onChange={(ev)=>{
                    setPassword(ev.target.value);
                    if (password.length>=1) {
                      setIsrrpassword(false);
                    }
                    setAlert(false);
                  }}/>
                  {iserrpassword?<div className="text text-danger">{errpassword}</div>:''}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-12">
                  <div className="form-check small">
                    <input className="form-check-input" type="checkbox" id="gridCheck1" />
                    <label className="form-check-label " for="gridCheck1"> Remember Me / <Link to="resetpassword" className="font-weight-bold">Forgot Password ?</Link> </label>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-12">
                  <div className="form-check p-0">
                    <Link to="" className="btn btn-primary" onClick={studLogin} disabled={isbtn}>Login</Link>
                    {/* <button className="btn btn-primary" onClick={()=>{
                                      alert("hello");
                                    }}>Login</button> */}
                  </div>
                </div>
              </div>

          </div>
          </div>
         <div className="small mt-2 copyrighttext">&copy; Copyright 2022 <a href="http://www.safalata.in" target="_blank"><u>Safalata</u></a>. All Rights Reserved.</div>
      </div>   
    </div>

        

        </main>
        <Footer/>
        </>
    );
}
export default Login;