function SectionMap() {
    const mapstyle={
        border:0,
        width: '100%',
        height: '350px'
    };
    return(
        <>
        <div className="map-section">
        <iframe style={mapstyle} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3675.4488400175683!2d88.41835381443993!3d22.896810026763433!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f896a019012603%3A0xcdfd66bf41d1f66e!2s195%2C%20Kailash%20Das%20Rd%2C%20Naihati%2C%20Kolkata%2C%20West%20Bengal%20743165!5e0!3m2!1sen!2sin!4v1642784747747!5m2!1sen!2sin" width="600" height="450" frameborder="0" allowfullscreen="" loading="lazy"></iframe>
        </div>
        </>
    );
}
export default SectionMap;