import {Link} from 'react-router-dom';

function Footer() {

    const imgstyle={
        width: '80%',
        marginBottom: '20px'
    };

    return(
        <>
        <footer id="footer">

            <div className="footer-top">
                <div className="container">
                    <div className="row">

                    <div className="col-lg-3 col-md-6 footer-contact">
                        <img src="../assets/img/logo.png" alt="" className="img-fluid" style={imgstyle}/>
                        <p>
                        195, Kailash Das Road, Gauripur, <br/>
                        Nearest Railway Station-Naihati<br/>
                        Dist - North 24 Parganas<br/>
                        West Bengal - 743166<br/><br/>
                        <strong>Phone:</strong> 9339764227<br/>
                        <strong>Whatsapp:</strong> 8013181226<br/>
                        <strong>Email:</strong> dshaw0112@gmail.com<br/>
                        </p>
                    </div>

                    <div className="col-lg-2 col-md-6 footer-links">
                        <h4>Useful Links</h4>
                        <ul>
                        <li><i className="bx bx-chevron-right"></i> <Link to="/">Home</Link></li>
                        <li><i className="bx bx-chevron-right"></i> <Link to="/academy">Our Academy</Link></li>
                        <li><i className="bx bx-chevron-right"></i> <Link to="/admission">Admission</Link></li>
                        <li><i className="bx bx-chevron-right"></i> <Link to="/successstory">Success Story</Link></li>
                        <li><i className="bx bx-chevron-right"></i> <Link to="/contact">Contact</Link></li>
                        <li><i className="bx bx-chevron-right"></i> <Link to="/privacypolicy">Privacy</Link></li>
                        <li><i className="bx bx-chevron-right"></i> <Link to="/terms-and-conditions">Terms &amp; Conditions</Link></li>
                        <li><i className="bx bx-chevron-right"></i> <Link to="/refund-cancellation-policy">Refund/ Cancellation Policy</Link></li>
                        </ul>
                    </div>

                    <div className="col-lg-3 col-md-6 footer-links">
                        <h4>Our Services</h4>
                        <ul>
                        <li><i className="bx bx-chevron-right"></i> <Link to="/studportal/login">Student Portal</Link></li>
                        <li><i className="bx bx-chevron-right"></i> <Link to="/register">New Registration</Link></li>
                        <li><i className="bx bx-chevron-right"></i> <Link to="#">Online Examination</Link></li>
                        <li><i className="bx bx-chevron-right"></i> <Link to="/course">Online Course</Link></li>
                        </ul>
                    </div>

                    <div className="col-lg-4 col-md-6 footer-newsletter">
                        <h4>ABOUT OUR INSTITUTE</h4>
                        <p>Safalata- An Institute under M/S</p>
                        <p>Shraddha Group of Education,</p>
                        <p>Registered under WB Act XXII of 1993,</p>
                        <p>Reg No - NM/2002717823.</p>

                        {/* <input type="email" name="email"/><input type="submit" value="Subscribe"/> */}
                        <a style={{borderRadius: '10%'}} href="https://play.google.com/store/apps/details?id=in.safalata.mocktest" target="_blank"><img src="assets/img/Google-Play-Logo.png" /></a>

                    </div>

                    </div>
                </div>
            </div>

            <div className="container d-md-flex py-4">
                <div className="me-md-auto text-center text-md-start">
                    <div className="copyright">
                    &copy; Copyright <strong><span>safalata.net</span></strong>. All Rights Reserved
                    </div>
                    <div className="credits">
                        Designed by <Link to="#" onClick={()=>{
                            window.open("https://www.cheapwebguru.com/")
                        }} >CHEAPWEBGURU.COM</Link>
                    </div>
                </div>
                <div className="social-links text-center text-md-right pt-3 pt-md-0">
                    <Link to="#" className="twitter"><i className="bx bxl-twitter"></i></Link>
                    <Link to="#" className="facebook"><i className="bx bxl-facebook"></i></Link>
                    <Link to="#" className="instagram"><i className="bx bxl-instagram"></i></Link>
                    <Link to="#" className="google-plus"><i className="bx bxl-skype"></i></Link>
                    <Link to="#" className="linkedin"><i className="bx bxl-linkedin"></i></Link>
                </div>
            </div>

        </footer>
        </>
    );
}
export default Footer;